<template>
  <v-container fluid class="text-justify">
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">Erkl&auml;rung zur Barrierefreiheit</h2>

        <p>
          Als &ouml;ffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bem&uuml;ht, unsere Websites und
          mobilen Anwendungen im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes (BGG NRW) sowie
          der Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW) zur Umsetzung der Richtlinie (EU) 2016/2102
          barrierefrei zug&auml;nglich zu machen.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Stand der Vereinbarkeit mit den Anforderungen</h3>
        <p>
          Die Anforderungen der Barrierefreiheit ergeben sich aus Paragraphen 3 Abs&auml;tze 1 bis 4 und Paragraph 4 der
          Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW), die auf der Grundlage von § 10e
          Behindertengleichstellungsgesetz (BGG NRW) erlassen wurde.
        </p>

        <p>
          Die &uuml;berpr&uuml;fung der Einhaltung der Anforderungen beruht auf einer am
          {{ created }} durchgef&uuml;hrten Selbstbewertung.
        </p>

        <p>
          Die Webanwendung Geodatenbereitstellung steht verschiedenen Nutzergruppen zur Verf&uuml;gung, welche
          unterschiedliche Funktionalit&auml;ten und Inhalte sehen und nutzen k&ouml;nnen. Daher h&auml;ngt der Grad der
          Barrierefreiheit von der Nutzergruppe ab. Aufgrund der &uuml;berpr&uuml;fung ist die Website je nach
          Nutzergruppe mit den zuvor genannten Anforderungen wegen der folgenden Unvereinbarkeiten teilweise vereinbar.
        </p>
        <p>
          Die nachstehend aufgef&uuml;hrten Pr&uuml;fschritte sind nicht erf&uuml;llt und damit die Inhalte nicht
          barrierefrei.
        </p>
        <p>Nicht erf&uuml;llt:</p>
        <ul class="mb-4">
          <li>
            Tastaturbedienbarkeit und Tastaturfokus
            <ul>
              <li>Listeneintr&auml;ge in den Unterkategorien sind nicht erreichbar</li>
            </ul>
          </li>
        </ul>
        <p>
          Alle aufgef&uuml;hrten Probleme wurden bzw. werden noch behoben. Ein Nachtest erfolgt zum n&auml;chst
          m&ouml;glichen Zeitpunkt.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">
          Datum der Erstellung bzw. der letzten Aktualisierung der Erkl&auml;rung
        </h3>
        <p>Diese Erkl&auml;rung wurde am {{ created }} erstellt.</p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Nicht barrierefreie Inhalte</h3>

        <p>.... Wir bem&uuml;hen uns um eine schnellstm&ouml;gliche Behebung der noch bestehenden Barrieren.</p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">
          Barrieren melden: Kontakt zu den Feedback Ansprechpartnern
        </h3>
        <p>
          Sie m&ouml;chten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit
          erfragen? F&uuml;r Ihr Feedback sowie alle weiteren Informationen sprechen Sie unsere verantwortlichen
          Kontaktpersonen
          <a :href="reportingForm">limgeo@it.nrw.de</a> an.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Schlichtungsverfahren</h3>

        <p>
          Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende L&ouml;sung gefunden
          wurde, k&ouml;nnen Sie sich an die Schlichtungsstelle nach § 10d Behindertengleichstellungsgesetz NRW (BGG
          NRW) wenden. Die Schlichtungsstelle BGG NRW hat die Aufgabe, bei Konflikten zum Thema Barrierefreiheit
          zwischen Menschen mit Behinderungen und &ouml;ffentlichen Stellen des Bundes eine außergerichtliche
          Streitbeilegung zu unterst&uuml;tzen. Das Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand
          eingeschaltet werden. Weitere Informationen zum Schlichtungsverfahren und den M&ouml;glichkeiten der
          Antragstellung erhalten Sie unter:
          <a
            href="https://www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik
          </a>
        </p>
        <p>
          Direkt kontaktieren k&ouml;nnen Sie die Schlichtungsstelle BGG NRW unter:
          <a href="mailto:ombudsstelle-barrierefreie-it@mags.nrw.de">ombudsstelle-barrierefreie-it(at)mags.nrw.de</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    created: '17.10.2022',
    updated: new Date('2022-02-28'),
    reportingForm: 'mailto:limgeo@it.nrw.de'
  })
};
</script>
